<div class="maintenance-container">
  <h3>More coming soon!</h3>
  <svg fill="#000000"
       id="Capa_1"
       xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 541.314 541.314"
       xml:space="preserve">
    <g>
      <g id="iconos_7_">
        <polygon points="433.497,312.025 397.063,368.51 372.938,358.776 288.864,512.129 387.635,512.129 430.915,454.754
          468.075,454.754 507.836,512.129 541.314,512.129 464.68,320.478 		" />
        <polygon points="463.073,464.317 435.687,464.317 399.617,512.129 496.208,512.129 		" />
        <path d="M38.384,420.339l-28.41,91.79h39.445l31.556-91.418l9.008-71.145h30.055l27.043,73.727l-16.524,88.836h38.279
          l18.819-100.616l-18.819-53.034l-6.837-23.992l103.562,58.676l-19.431,33.736l45.125,26l47.735-82.858l-45.125-26.001
          l-18.752,32.551L154.53,308.276l-6.502-22.835l11.829-47.918l5.097,43.797l8.186,21.257l32.302,18.886l-13.397-47.602
          l-10.356-91.456l-33.469-1.377l-5.881-16.18c22.424-4.581,39.302-23.906,40.545-47.373l-100.75-13.359
          c-0.736,3.442-1.157,7.009-1.157,10.672c0,17.681,8.989,33.258,22.644,42.429l-0.889-0.096l-20.167,16.954l-25.838-2.17L0,221.497
          v75.477l45.059,11.131L38.384,420.339z M28.688,269.558v-22.539l19.125-17.471v44.313L28.688,269.558z" />
        <path d="M182.443,107.77l15.31,2.027c2.037-45.508-17.738-75.286-53.541-80.029c-2.936-0.392-5.843-0.583-8.721-0.583
          c-31.709,0-54.784,23.208-63.82,63.896l13.34,1.769L182.443,107.77z" />
      </g>
    </g>
  </svg>
</div>
