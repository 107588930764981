import { Component } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {

  public subPages: string[] = [
    "home",
    "skills",
    "legal"
  ];
  public isExpanded: boolean = false;
  public navbarContainerClassNames: string [] = [];
  public svgClassNames: string[] = [];

  constructor() {
    this.updateNavbarStyle();
  }

  toggleNavbar(): void {
    this.isExpanded = !this.isExpanded;
    this.updateNavbarStyle();
  }

  private updateNavbarStyle(): void {
    if (this.isExpanded) {
      this.svgClassNames = ['rotate-expand-icon'];
      this.navbarContainerClassNames = ['rotate-expand-navbar'];
    } else {
      this.svgClassNames = ['rotate-collapse-icon'];
      this.navbarContainerClassNames = ['rotate-collapse-navbar'];
    }
  }
}
